import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions?</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is Crypto Launchpad Development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Crypto Launchpad Development is the creation of a customized fundraising website that enhances budding startups earn revenue by launching decentralized projects. Here, newbie startups can distribute tokens for investors at ease before listing them in exchanges.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How long does it take to create a crypto launchpad?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The approximate time to create a crypto launchpad would be around 3 to 9 months. But, this may exceed depending on your choice of development method, back-end & front-end features, testing, and customization.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How much does crypto launchpad development cost?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The average cost of developing a crypto launchpad would range between $25,000 and $35,000. Yet, this is not the exact amount for creation. The cost may vary based on factors like customization, feature integration, and add-on modules.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How does the token sale process work on a Crypto Launchpad?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The crypto launchpad team fixes specific prices for the tokens at the beginning of the token sale. Once the tokens are distributed, investors can buy or purchase them accordingly. The crypto token price fluctuates depending on the demand and value in the market. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How does a Crypto Launchpad Development Company handle regulatory compliance?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">By analyzing the jurisdiction, auditing smart contracts, and providing insurance coverage, the White-label crypto launchpad development company handles the regulations. To ensure a solid compliance framework, companies get consultation from legal experts.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection