import React from 'react'


const Popular = () => {


  return (
    <section className="devapproach pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">Benefits Of</span>  Our Crypto Launchpad Development Services
            </h2>
            <p className="pharagraph head">Our seamless crypto launchpad development services come with pre-loaded benefits for startups, and users.
            </p>
          </div>
        </div>
        <div className='row popular'>
            <div className='col-md-12 col-lg-3'>
                <h3>New token launch</h3>
                <p className='pharagraph'>Presently, the market is flooded with numerous potential digital assets. Hence, a launchpad would be the perfect way to distribute new tokens. The value and demand of those tokens would increase significantly over the years.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Ecosystem Growth</h3>
                <p className='pharagraph'>You can gather like-minded people through crypto launchpad development. This paves the way for next-gen projects, development, and innovations. This also enables a long-term engagement between startups and launchpad admin.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Secured Fundraising Models</h3>
                <p className='pharagraph'>Our launchpad comes along with exemplary fundraising models. For each crowdfunding method, we have integrated specialized features, functionalities, and plugins. Through this, you can gain the attention of all sorts of startups for effective fundraising. </p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Profitability</h3>
                <p className='pharagraph'>One of the significant benefits of crypto launchpad development is its earning streams. You can earn an ample amount from startups for listing the decentralized crypto projects.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Popular