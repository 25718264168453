import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Crypto Launchpad</span> Development Company</h1>
                <p className="pharagraph">Ascend, Activate, and Achieve with our crypto launchpad development company!</p>
                <p className="pharagraph">Hire our developers to initiate the idea of a top-notch crypto launchpad development with premium features. Our developer team intends to offer an exemplary launchpad website for a secure token sale environment and launching next-gen crypto projects.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-launchpad/crypto-launchpad-development-company.webp"
                alt="crypto launchpad development company"
                className='d-none d-md-block'
                width={743}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection