import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What Makes Coinsclone</span> The Best Crypto Launchpad Development Company?</h2>
              <p>Elevate your dream crypto project with us! Coinsclone is a leading crypto launchpad development company that has nearly fulfilled all the desires of budding startups. We get the spotlight for developing a crypto launchpad because we aid new advancements in our creation process. Our team’s expert quality lies in producing a platform that would serve as a springboard for numerous crypto projects. </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-launchpad/why-choose.png"
                alt="Best Crypto Launchpad Development Company"
                placeholder='none'
                width={491}
                height={551}
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Get in touch with our development company to embrace the power of launching innovative ideas and revolutions. We possess an efficient portfolio of all sorts of crypto launchpad development services. 
              </p>
              <ul>
                <li>Skilled set of Developers</li>
                <li>Reputed Clone script providers</li>
                <li>Enables Transparent Development Process</li>
                <li>Complete Package at Affordable Cost</li>
                <li>Scalability</li>
                <li>Instant Issue Management</li>
                <li>Fastest Project Delivery</li>
              </ul>
              <p>Stay ahead in the cryptocurrency market with our development company!</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose