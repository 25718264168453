import React from 'react'



class OurVarious extends React.Component {


  render() {

    return (
      <section className="icon pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Services Offered</span> By Our Crypto Launchpad Development Company</h2>
          <p className='text-center'>We at Coinsclone offer premium-level fundraising models for newbie startups to raise funds for their decentralized projects. Our top-notch launchpad development services are listed below.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial Coin Offering (ICO)
                  </h3>
                <p className="pharagraph">ICO is a crowdfunding method that possesses the same characteristics as the traditional IPO (Initial Public Offering) method. Usually budding startups pick this fundraising model to reap profits and sell new tokens. This is where small business turns their aspirations into reality.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Security Token Offering (STO)
                </h3>
                <p className="pharagraph">Like ICO, STO also helps startups to raise funds yet the only difference is security tokens are issued here. Investors who are searching for advanced-level security, choose this method. Startups follow SEC guidelines, norms, and regulations to execute the project.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial Exchange Offering (IEO)
                </h3>
                <p className="pharagraph">Startups approach popular centralized exchanges to sell their tokens to a potential set of investors. As trusted centralized exchanges are involved in token distribution, security protocols are more effective here than other fundraising models. Our developers are specialized in integrating this service into the launchpad.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial DEX Offering (IDO)
                </h3>
                <p className="pharagraph">IDO is a popular crowdfunding way among startups where the digital tokens are listed on the DEX platform. Without third-party interference, the project is executed and listed in the launchpad. The DEX platform takes complete responsibility for selling the tokens seamlessly.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial NFT Offering (INO)
                </h3>
                <p className="pharagraph">The method of getting immense support from investors is INO. Through this method, startups can earn enough funds by selling unique non-fungible tokens in the launchpad. This strengthens the demand for NFTs and digital assets.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial Farm Offering (IFO)
                </h3>
                <p className="pharagraph">The IFO method is a successor of the ICO fundraising method for distributing newly created tokens. Here, startups who have decentralized projects would raise funds at ease. Peer-to-peer token sale takes place where the transactions are executed securely.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial Insurance Offering (IIO)
                </h3>
                <p className="pharagraph">The IIO crowdfunding model helps users purchase tokens via European Trading options. With this method, users can circulate their tokens to numerous market investors. Token holders can avoid sudden market fluctuations. </p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Initial Gaming Offering (IGO)
                </h3>
                <p className="pharagraph">Metaverse gamers and developers use this type of fundraising method for their early-stage gaming projects. Even before the public token sale, users can utilize the in-game rewards. Users can stake those gaming assets for future use.</p>
              </div>
              </div>
          </div>
          <a href="/contact-us/" className="bluebtn mt-2 m-auto d-table">Free Trial</a>
        </div>
      </section>
    )
  }
}

export default OurVarious