import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Crypto Launchpad Development Company - <span className='bluecolor'>Coinsclone</span></h2>
            <div className="text-center">
              <p className="pharagraph text-center">We, a leading crypto launchpad development company help startups to create a customized fundraising website with significant features. Startups can earn revenue by launching decentralized projects. Here, newbie startups can distribute tokens for investors at ease before listing them in exchanges. In the booming crypto market, we stand ahead with a skilled set of developers, technicians, mobile app creators, and testers. We assure you that our crypto launchpad development services would be a turnkey solution.
              </p>
              <p className="pharagraph text-center mb-0">We specialize in developing a world-class launchpad with premium characteristics. As we possess a skilled, dedicated, and potential set of developers, you can create your launchpad that suits your business vision. The white label solutions we offer with our crypto launchpad development help you to deploy your launchpad with top-notch development services, features, and functionalities.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis